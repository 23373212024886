/* eslint-disable react-hooks/rules-of-hooks */
import AppBar from './components/app-bar/App-bar';

import { RouterPaths, StoreActions } from '@passion/enums';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Suspense, useEffect } from 'react';
import {
  Navigate,
  BrowserRouter as Router,
  useLocation,
  useParams,
  useRoutes,
} from 'react-router-dom';
import Loader from './components/loader/loader';
import NewPageForm from './pages/create-page/Create-page';
import HomePage from './pages/home-page/Home-page';
import { LandingPagePreview as LandingPageSubmission } from './pages/landing-page/LandingPage-preview';
import LoginPage from './pages/login/Login';
import OnBoarding from './pages/on-boarding/On-boarding';
import SettingsPage from './pages/settings-page/Settings-page';
import SubmissionPage from './pages/submission-page/Submission-page';
import { ApiRoutes } from './services';
import { AppProvider, useAppContext } from './store/App-context';
import axiosInstance from './utils/axios.instance';
import AxiosNavigation from './utils/axios.navigation';

function AppRoutes() {
  const { id } = useParams();
  const location = useLocation();
  const { state, dispatch } = useAppContext();
  useEffect(() => {
    console.log({ id, state });
    if (
      state.formData.id &&
      location.pathname.includes(RouterPaths.LANDING_PAGE_PREVIEW)
    )
      axiosInstance
        .get(ApiRoutes.LOGIN_STATUS)
        .then((res) => {
          if (res.data?.data?.isSuccess) {
            dispatch({
              type: StoreActions.TOGGLE_LOGIN,
              payload: { isLoggedIn: true },
            });
          }
        })
        .catch(function (error) {
          if (error.response) {
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
        });
  }, [null]);

  if (window.location.hostname === import.meta.env.VITE_LANDING_DOMAIN) {
    const routes = useRoutes([
      {
        path: '',
        children: [
          {
            path: ':id',
            element: <LandingPageSubmission />,
          },
          // {
          //   path: RouterPaths.ERROR,
          //   element: <ErrorPage />,
          // },
          {
            path: RouterPaths.ERROR,
            Component: () => {
              window.location.href = 'https://passion.page';
              // <Navigate to={'https://passion.page'} replace />,
              return null;
            },
          },
          {
            path: '*',
            Component: () => {
              window.location.href = 'https://passion.page';
              // <Navigate to={'https://passion.page'} replace />,
              return null;
            },
            // element: <Navigate to={'https://passion.page'} replace />,
          },
        ],
      },
    ]);
    return routes;
  } else {
    const routes = useRoutes([
      {
        path: '',
        element: <AppBar />,
        children: [
          {
            path: RouterPaths.ON_BOARDING,
            element: (
              // <Suspense>
              <OnBoarding />
              // </Suspense>
            ),
          },
          {
            path: RouterPaths.CREATE_LANDING_PAGE,
            element: (
              // <Suspense>
              <NewPageForm />
              // </Suspense>
            ),
          },
          {
            path: RouterPaths.LANDING_PAGE_PREVIEW,
            children: [
              {
                path: ':id',
                element: <LandingPageSubmission />,
              },
            ],
          },

          // </Suspense>
          {
            path: RouterPaths.LANDING_PAGE_SETTINGS,
            element: (
              // <Suspense>
              <SettingsPage />
              // </Suspense>
            ),
          },
          // {
          //   path: RouterPaths.LANDING_PAGE_PREVIEW,
          //   element: <LandingPageSubmission />,
          // },
          {
            path: RouterPaths.HOME,
            element: (
              // <Suspense>
              <HomePage />
              // </Suspense>
            ),
          },
          {
            path: ':id',
            element: <LandingPageSubmission />,
          },
          {
            path: RouterPaths.SUBMISSIONS_PAGE,
            element: (
              <Suspense>
                <SubmissionPage />
              </Suspense>
            ),
          },
          {
            path: '*',
            element: <Navigate to={RouterPaths.ON_BOARDING} />,
          },
        ],
      },
    ]);

    return routes;
  }
}

function App() {
  return (
    <AppProvider>
      <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
        <Router basename="/">
          <AxiosNavigation />
          <AppRoutes />
          <LoginPage />
          <Loader />
        </Router>
      </GoogleOAuthProvider>
    </AppProvider>
  );
}

export default App;
