import { RouterPaths, StoreActions } from '@passion/enums';
import { ApiRoutes } from '@passion/services';
import { useAppContext } from '@passion/store';
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from './axios.instance';

export const AxiosNavigation: React.FC = () => {
  const navigate = useRef(useNavigate());
  const { state, dispatch } = useAppContext();
  const location = useLocation();

  useEffect(() => {
    /* This code block is defining an interceptor for the Axios instance that will be triggered when a
        request is made. The `use()` method takes two callback functions as arguments: the first one is
        called with the request configuration object and can be used to modify the request or headers if
        needed, and the second one is called if there is an error. In this case, the first callback function
        simply returns the unmodified request configuration object, while the second callback function
        returns a rejected Promise with the error object. */
    const responseInterceptor = axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        if (
          !config.url?.includes(ApiRoutes.LOGIN_STATUS) &&
          !config.url?.includes(ApiRoutes.CSRF)
        ) {
          dispatch({
            type: StoreActions.TOGGLE_LOADING,
          });
        }

        // Modify request or headers if needed
        return config;
      },
      (error) => {
        dispatch({
          type: StoreActions.TOGGLE_LOADING,
          payload: {
            loading: false,
          },
        });
        dispatch({
          type: StoreActions.GLOBAL_ERROR_STATE,
          payload: { globalErrorState: true },
        });
        return Promise.reject(error);
      },
    );

    /* This code block is defining an interceptor for the Axios instance that will be triggered when a
        response is received. The `use()` method takes two callback functions as arguments: the first one is
        called if the response is successful, and the second one is called if there is an error. */
    const requestInterceptor = axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        if (
          response.config.url === ApiRoutes.CSRF ||
          response.config.url?.includes(ApiRoutes.IS_REQUESTED_URL_AVAILABLE) ||
          response.config.url?.includes(ApiRoutes.LOGIN_STATUS) ||
          location.state?.id
        ) {
          dispatch({
            type: StoreActions.TOGGLE_LOADING,
            payload: { loading: false },
          });
          return response;
        }

        if (
          response.status === 200 &&
          response.data.isSuccess &&
          !state.isLoggedIn
        ) {
          dispatch({
            type: StoreActions.TOGGLE_LOGIN,
            payload: {
              isLoggedIn: true,
            },
          });
        }
        dispatch({
          type: StoreActions.TOGGLE_LOADING,
          payload: { loading: false },
        });
        return response;
      },
      (error) => {
        dispatch({
          type: StoreActions.TOGGLE_LOADING,
          payload: { loading: false },
        });
        switch (error?.response?.status) {
          case 401: {
            if (error.config.url?.includes(ApiRoutes.LOGIN_STATUS)) {
              navigate.current(RouterPaths.ON_BOARDING);
              return;
            }
            if (!location.state?.id) {
              navigate.current(RouterPaths.ON_BOARDING);
            }
            return;
          }
          default: {
            dispatch({
              type: StoreActions.GLOBAL_ERROR_STATE,
              payload: { globalErrorState: true },
            });
          }
        }

        // Handle error
        return Promise.reject(error);
      },
    );
    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  });

  return <></>;
};

export default AxiosNavigation;
