export enum RouterPaths {
	ON_BOARDING = "/",
	CREATE_LANDING_PAGE = "/create-landing-page",
	LANDING_PAGE_SETTINGS = "/landing-page-settings",
	LANDING_PAGE_PREVIEW = "/landing-page-preview",
	HOME = "/home",
	LANDING_PAGE_SUBSCRIBE = "/landing-page-subscribe/:id",
	SUBMISSIONS_PAGE = "/submissions-page",
	ERROR="/"
}
