import { useEffect, useRef } from 'react';

import style from './Trustbox.module.scss';
const TrustBox = () => {
  const ref = useRef(null);
  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div
      ref={ref}
      id="trustbox"
      className={style.trustpilot_widget}
      data-locale="en-US"
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="5e54395c7170470001dc8691"
      data-style-height="150px"
      data-style-width="100%"
      data-theme="light"
      data-text-color="#000000"
    >
      <a
        href="https://www.trustpilot.com/review/passion.io"
        target="_blank"
        rel="noopener"
      >
        {' '}
        Trustpilot
      </a>
    </div>
  );
};
export default TrustBox;
