import { Avatar, Container, Typography } from '@mui/material';
import style from './submission-success.module.scss';

enum ChannelDesc {
  'sms' = 'phone',
  'email' = 'email',
  'telegram' = 'telegram',
  'whatsapp' = 'whatsapp',
}

interface SubmissionSuccessProps {
  channel: keyof typeof ChannelDesc;
}
export const SubmissionSuccess = (props: SubmissionSuccessProps) => {
  const { channel } = props;

  return (
    <Container className={style.submission_success_container}>
      <Container className={style.icon_container}>
        <Avatar src="assets/icons/success-black.svg" />
      </Container>
      <Typography className={style.greeting_title}>Thank you!</Typography>
      <Typography className={style.greeting_subtitle}>
        Please check your {ChannelDesc[channel]}
      </Typography>
    </Container>
  );
};

export default SubmissionSuccess;
