import { Avatar, Container, IconButton, Typography } from '@mui/material';

import { ChannelTypes } from '@passion/enums';
import { submissionCard } from '@passion/types';
import dayjs from 'dayjs';
import style from './Submission.module.scss';

export const Submission: React.FC<submissionCard> = (props: submissionCard) => {
  const { contactInfo, date, iconPath, onCopy, type } = props;

  const handleSubmissionCopy = () => {
    navigator.clipboard.writeText(contactInfo).then((data) => {
      navigator.clipboard.readText().then((res) => {
        onCopy();
      });
    });
  };

  return (
    <Container className={style.submission_card_container}>
      <Avatar
        src={`assets/icons/submission-${
          type == ChannelTypes.SMS ? 'sms' : 'email'
        }.svg`}
        classes={{
          circular: style.submission_type_circular,
          img: `${
            type === ChannelTypes.EMAIL
              ? style.submission_type_icon
              : style.submission_type_icon_sms
          } ${type === ChannelTypes.SMS ? style.icon16 : style.icon12}`,
        }}
      />
      <Container className={style.submission_info_container}>
        <Typography className={style.contact_info}>{contactInfo}</Typography>
        <Typography className={style.date_info}>
          {dayjs(date).format('MMMM DD,YYYY H:ma')}
        </Typography>
      </Container>
      <IconButton
        onClick={handleSubmissionCopy}
        className={style.copy_to_clipboard_button}
        sx={{ p: 0 }}
      >
        <Avatar
          src="assets/icons/copy-submission.svg"
          sx={{ borderRadius: '0%', height: '24px', width: '22px' }}
        />
      </IconButton>
    </Container>
  );
};

export default Submission;
