import {
  Avatar,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from '@mui/material';
import { CustomInput, SubmissionSuccess } from '@passion/components';
import { ChannelTypes, RouterPaths, StoreActions } from '@passion/enums';
import { ApiRoutes } from '@passion/services';
import { useAppContext } from '@passion/store';
import { landingPageForm } from '@passion/types';
import { MuiTelInputInfo } from 'mui-tel-input';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import axiosInstance from 'src/utils/axios.instance';
import style from './LandingPage-preview.module.scss';
export const LandingPagePreview = () => {
  const { state, dispatch } = useAppContext();
  const { id } = useParams();
  const [chosenChannel, setChoseChannel] = useState(state.formData.channel[0]);
  const [submissionValue, setSubmissionValue] = useState('');
  const [edit = false, setEdit] = useState(useLocation()?.state || {});
  const [haveId, setHaveId] = useState(state.formData.id);
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
  const location = useLocation();
  const [isInputError, setIsInputError] = useState(false);
  const [smsSubmission, setSmsSubmission] = useState('');
  const [emailSubmission, setEmailSubmission] = useState('');
  const [searchQuery, setSearchQuery] = useSearchParams();
  const [phoneInputPrefix, setPhoneInputPrefix] = useState('US');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title =
      state.formData.description ||
      'Build a list of people who want to buy from you';
    const descMeta = document.createElement('meta');
    descMeta.setAttribute('property', 'og:description');
    descMeta.setAttribute('content', 'opt in one click');
    document.head.appendChild(descMeta);
    const titleMeta = document.createElement('meta');
    titleMeta.setAttribute('property', 'og:title');
    titleMeta.setAttribute('content', state.formData.description || 'default');
    document.head.appendChild(titleMeta);
    const urlMeta = document.createElement('meta');
    urlMeta.setAttribute('property', 'og:image');
    urlMeta.setAttribute('content', state.formData.file || 'default');
    document.head.appendChild(urlMeta);
  }, [state.formData.description]);

  useEffect(() => {
    const isEdit = searchQuery.get('edit');
    const pathname = location.pathname.split('/');

    if (pathname.includes(RouterPaths.LANDING_PAGE_PREVIEW.slice(1))) {
      if (id) {
        const transformedId = id.toLowerCase();
        location.state = {
          transformedId,
        };
        if (!isEdit) {
          navigate('/' + transformedId);
        } else {
          setEdit(true);
        }
      }
    } else {
      if (id) {
        const transformedId = id.toLowerCase();
        location.state = {
          transformedId,
        };
        navigate('/' + transformedId);
        setEdit(false);
      }
    }

    if (id && !state.isLoggedIn) {
      setLandingPageValues();
    }

    Helmet.peek();
    return () => {};
  }, []);

  const navigate = useNavigate();

  const setLandingPageValues = async () => {
    if (!id) {
      return;
    }
    const landingPageDetails = await axiosInstance.get<{
      data: landingPageForm;
    }>(`${ApiRoutes.PAGES}/${id.toLowerCase()}`);
    if (landingPageDetails.data.data) {
      setChoseChannel(landingPageDetails.data.data.channel[0]);
      dispatch({
        type: StoreActions.UPDATE_FORM_VALUES,
        payload: {
          landingPageForm: landingPageDetails.data.data,
        },
      });
      setHaveId(landingPageDetails.data.data.id);
    } else {
      navigate(RouterPaths.ON_BOARDING);
    }
  };

  const handleOnChannelSelect = (channel: string) => {
    setChoseChannel(
      ChannelTypes[channel.toUpperCase() as keyof typeof ChannelTypes],
    );

    setSubmissionValue(
      ChannelTypes[channel.toUpperCase() as keyof typeof ChannelTypes] ===
        ChannelTypes.SMS
        ? smsSubmission
        : emailSubmission,
    );
  };
  const onSubmissionInputChange = (value: string) => {
    setIsInputError(false);

    let validatedValue = value;
    if (chosenChannel === ChannelTypes.SMS) {
      // validatedValue = value.replace(/[^0-9]/g, '');
      // TODO: validate phone number
      setSmsSubmission(validatedValue);
    } else {
      setEmailSubmission(validatedValue);
    }

    setSubmissionValue(validatedValue);
  };

  const handleOnSubmit = async () => {
    if (!isError()) {
      await sendSubmissionToServer();
      return;
    }
    setIsInputError(true);
  };

  const sendSubmissionToServer = async () => {
    let _submissionValue = submissionValue;
    if (
      chosenChannel === ChannelTypes.EMAIL &&
      _submissionValue.includes('+')
    ) {
      _submissionValue = emailManipulation();
    }

    console.log({ countryCode: phoneInputPrefix });
    const res = await axiosInstance.post(ApiRoutes.SUBMISSIONS, {
      contactInfo: _submissionValue,
      type: chosenChannel,
      id: state.formData.id,
      countryCode: phoneInputPrefix,
    });
    if (res.data.isSuccess) {
      setIsSubmittedSuccessfully(true);
    } else {
      setIsInputError(true);
    }
  };

  const handleExitClick = () => {
    document.title = 'Build a list of people who want to buy from you';
    navigate(RouterPaths.HOME);
  };

  const handleEditClick = () => {
    navigate(RouterPaths.CREATE_LANDING_PAGE, {
      state: {
        edit: true,
      },
    });
  };

  const handleShareClick = async () => {
    if (!navigator.share) {
      await navigator.clipboard.writeText(
        `https://${import.meta.env.VITE_LANDING_DOMAIN}/${state.formData.url}`,
      );
      alert('URL has been copied to the clipboard');
    } else {
      const image = await fetch('logo18.png');
      await navigator.share({
        url: `https://${import.meta.env.VITE_LANDING_DOMAIN}/${
          state.formData.url
        }`,
      });
    }
  };

  const getSubmissionError = () => {
    if (chosenChannel === ChannelTypes.EMAIL) {
      return 'Email address in invalid';
    }
    if (chosenChannel === ChannelTypes.SMS) {
      return 'Phone number in invalid';
    }
  };

  const emailManipulation = () => {
    return (
      submissionValue.slice(0, submissionValue.indexOf('+')) +
      submissionValue.slice(
        submissionValue.indexOf('@'),
        submissionValue.length + 1,
      )
    );
  };
  const isError = () => {
    let _submissionValue = submissionValue;
    if (chosenChannel === ChannelTypes.EMAIL && submissionValue.includes('+')) {
      _submissionValue = emailManipulation();
    }
    return (
      (chosenChannel === ChannelTypes.SMS &&
        !_submissionValue.match(
          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/,
        )) ||
      (chosenChannel === ChannelTypes.EMAIL &&
        !_submissionValue.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/))
    );
  };

  return haveId ? (
    <div
      className={`${style.page_container}  ${
        !state.formData.file ? style.spotted_background : style.dark_background
      }`}
    >
      {/* <Helmet>
        <title>{state.formData.description}</title>
        <meta property="og:url" content="https://passion.page/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={state.formData.description} />
        <meta property="og:description" content="opt in in one click" />

        <meta property="og:image" content={state.formData.file} />
        <meta property="og:image" content={state.formData.file} /> */}
      {/* <meta
          property="og:title"
          content={
            state.formData.description ||
            'Create a page in a few minutes that builds a list of emails, SMS, Whatsapp and Telegram contacts who are ready to buy.'
          }
          />
          <meta property="og:description" content="opt in in one click" />
        <meta property="og:image" content={state.formData.file} /> */}
      {/* </Helmet> */}
      {edit && (
        <Container
          className={`${style.landing_page_edit_action_container} ${style.flex_row_container}`}
        >
          <Container
            className={`${style.landing_page_exit_button_container} ${style.flex_row_container}`}
          >
            <IconButton
              onClick={handleExitClick}
              className={style.background_primary}
            >
              <Avatar src="assets/icons/exit.svg" className={style.icon} />
            </IconButton>
          </Container>
          <Container
            className={`${style.landing_page_actions_group} ${style.flex_row_container}`}
          >
            <IconButton
              onClick={handleEditClick}
              className={style.background_primary}
            >
              <Avatar src="assets/icons/edit.svg" className={style.icon_big} />
            </IconButton>
            <IconButton
              onClick={handleShareClick}
              className={style.background_primary}
            >
              <Avatar
                src="assets/icons/share.svg"
                className={style.share_icon}
              />
            </IconButton>
          </Container>
        </Container>
      )}
      <div
        className={`${style.landing_page_preview_container} ${
          state.formData.file?.length === 0
            ? ''
            : style.no_image_preview_container
        }`}
      >
        {state.formData.file?.length !== 0 && (
          <div className={style.image_container}>
            <img
              className={style.landing_page_image}
              src={state.formData.file}
            />
          </div>
        )}
        <Container
          className={`${style.landing_page_preview_page_bottom_content} 						
          ${!state.formData.file ? style.no_Landing_page_image : ''}
          `}
        >
          <h3 className={style.landing_page_preview_title}>
            {state.formData.description}
          </h3>
          {isSubmittedSuccessfully ? (
            <SubmissionSuccess channel={chosenChannel} />
          ) : (
            <div className={style.client_subscription_call_to_action}>
              <Container className={style.costumer_subscription_methods}>
                <Typography
                  fontFamily={'Inter'}
                  color={'#666666'}
                  fontWeight={700}
                >
                  Get notified
                </Typography>
                {state.formData.channel.length > 1 && (
                  <Container className={style.subscription_method_icons}>
                    {state.formData.channel.map((channel, index) => (
                      <div
                        className={style.channel_option_container}
                        key={channel + index}
                      >
                        <button
                          className={style.transparent_channel_button}
                          onClick={() => handleOnChannelSelect(channel)}
                        >
                          <img
                            className={`${style.channel_option_image} ${
                              index !== 0 ? style.second : ''
                            }`}
                            src={
                              chosenChannel === channel
                                ? `assets/icons/lps-${channel}.svg`
                                : `assets/icons/lp-${channel}.svg`
                            }
                            alt=""
                          />
                        </button>
                      </div>
                    ))}
                  </Container>
                )}
              </Container>
              <CustomInput
                error={isInputError}
                errorMessage={isInputError ? getSubmissionError() : ''}
                isPhoneNumberInput={chosenChannel === ChannelTypes.SMS}
                placeholder={
                  chosenChannel === ChannelTypes.SMS ? '' : 'example@mail.com'
                }
                name="submissionMessage"
                standAlone={true}
                className={style.subscription_to}
                type="outlined"
                label={
                  chosenChannel.charAt(0).toUpperCase() + chosenChannel.slice(1)
                }
                inputProps={{
                  maxLength: chosenChannel === ChannelTypes.SMS ? 12 : 50,
                }}
                onKeyDown={(key) => {
                  if (key?.key === 'Enter') {
                    handleOnSubmit();
                  }
                }}
                value={submissionValue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleOnSubmit} edge="end">
                        <img src="assets/icons/subscribe-icon.svg" />
                      </IconButton>
                    </InputAdornment>
                  ),
                  type: chosenChannel === ChannelTypes.EMAIL ? 'email' : 'tel',
                }}
                defaultValue={submissionValue}
                onChange={($event) =>
                  onSubmissionInputChange($event.target.value)
                }
                onChangeMobileInput={(number: MuiTelInputInfo) => {
                  if (
                    number.countryCode &&
                    number.countryCode !== phoneInputPrefix
                  ) {
                    setPhoneInputPrefix(number.countryCode || 'US');
                  }
                  onSubmissionInputChange(number.numberValue || '');
                }}
              />
              {chosenChannel === ChannelTypes.SMS && (
                <Typography className={style.submission_disclaimer}>
                  By submitting this form you consent to receiving marketing
                  text messages from Passion.io. You can unsubscribe at any
                  time.
                  <br />
                  <Link
                    href="https://passion.io/privacy-policy"
                    className={style.link}
                  >
                    Privacy policy &
                  </Link>
                  <Link
                    href="https://passion.io/terms-of-service"
                    className={style.link}
                  >
                    Terms.
                  </Link>
                </Typography>
              )}
            </div>
          )}
        </Container>
        {!edit && (
          <Button
            className={style.bottom_button}
            onClick={() => (window.location.href = 'https://passion.page/')}
          >
            <Container className={style.last_container}>
              <Typography className={style.bottom_page_link}>
                Build a<Link className={style.passion_io_link}> passion </Link>
                page
              </Typography>
            </Container>
          </Button>
        )}
      </div>
      <Outlet />
    </div>
  ) : (
    <div />
  );
};

export default LandingPagePreview;
