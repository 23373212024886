import style from './Create-page.module.scss';

import { Avatar, Button, Container, IconButton } from '@mui/material';

import {
  CustomDialog,
  CustomInput,
  customDialogProps,
} from '@passion/components';
import { ChannelTypes, RouterPaths, StoreActions } from '@passion/enums';
import { useAppContext } from '@passion/store';
import { Channel, landingPageForm } from '@passion/types';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloudinaryImage } from 'src/types/cloudinary-image';
import axiosInstance from 'src/utils/axios.instance';

export const NewPageForm = () => {
  const { state, dispatch } = useAppContext();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { edit = false } = useLocation()?.state || {};
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [file, setFile] = useState<string | undefined>(state.formData.file);
  const [description, setDescription] = useState(state.formData.description);
  const [channel, setChannel] = useState<ChannelTypes[]>(
    state.formData.channel,
  );

  const [formErrors, setFormErrors] = useState({
    file: false,
    description: false,
    channel: false,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (state.startRedirectProccess && state.isLoggedIn) {
      setDrawerOpen(true);
    }
  }, [state.startRedirectProccess]);

  const customDrawerProps: customDialogProps = {
    imgPath: 'assets/icons/email-marketing.gif',
    open: drawerOpen,
    title: 'Coming soon!',
    subtitle: 'We’ll email you when it’s ready',
    style: { maxWidth: '60%' },
    actions: [
      {
        text: 'ok',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        action: () => {
          //axios delete method
          setDrawerOpen(false);
        },
      },
    ],
  };
  useEffect(() => {
    const formData = state.formData;

    if (file !== formData.file) {
      setFile(formData.file);
    }
    if (description !== formData.description) {
      setDescription(formData.description);
    }
    if (channel !== formData.channel) {
      setChannel(formData.channel);
    }
  }, [state.formData]);

  const channels: Channel[] = [
    {
      name: 'email',
      svg: state.formData.channel.includes(ChannelTypes.EMAIL)
        ? 'assets/icons/email-chosen.svg'
        : 'assets/icons/email.svg',
      channelType: ChannelTypes.EMAIL,
      disabled: false,
    },
    {
      name: 'sms',
      svg: state.formData.channel.includes(ChannelTypes.SMS)
        ? 'assets/icons/sms-chosen.svg'
        : 'assets/icons/sms.svg',
      channelType: ChannelTypes.SMS,
      disabled: false,
    },
    {
      name: 'telegram',
      svg: state.formData.channel.includes(ChannelTypes.TELEGRAM)
        ? 'assets/icons/telegram.svg'
        : 'assets/icons/telegram.svg',
      channelType: ChannelTypes.TELEGRAM,
      disabled: true,
    },
    {
      name: 'whatsapp',
      svg: state.formData.channel.includes(ChannelTypes.WHATSAPP)
        ? 'assets/icons/whatsapp.svg'
        : 'assets/icons/whatsapp.svg',
      channelType: ChannelTypes.WHATSAPP,
      disabled: true,
    },
  ];

  /**
   * This function sets a new file if one is provided as an argument.
   * @param {File} [file] - The `file` parameter is an optional parameter of type `File`. It is used as
   * an argument to the `handleSetNewFile` function to set the `file` state variable to a new value. If the
   * `file` parameter is not provided, the `setFile` function will not be executed
   */
  const handleSetNewFile = async (inputfile?: File) => {
    if (inputfile) {
      try {
        const imageFormData = new FormData();
        imageFormData.append('file', inputfile);
        const cloudinaryImage: CloudinaryImage = (
          await axiosInstance.post('image/upload', imageFormData)
        ).data;

        if (cloudinaryImage?.public_id) {
          setNewFormImageValues(
            cloudinaryImage.secure_url,
            cloudinaryImage.public_id,
          );
        }

        return;
      } catch (err) {
        setNewFormImageValues('');
      }
    }
  };

  const setNewFormImageValues = (file: string, image_id: string = '') => {
    dispatch({
      type: StoreActions.UPDATE_FORM_VALUES,
      payload: {
        landingPageForm: {
          ...state.formData,
          file,
          image_id,
        },
      },
    });

    setFile(file);
  };

  const onContinueClicked = () => {
    if (channel) {
      const landingPageForm: landingPageForm = {
        file: file || '',
        description,
        channel,
      };
      dispatch({
        type: StoreActions.UPDATE_FORM_VALUES,
        payload: {
          landingPageForm,
        },
      });

      navigate(RouterPaths.LANDING_PAGE_SETTINGS, {
        state: {
          edit: edit,
        },
      });
    }
  };

  const resetFileInput = () => {
    dispatch({
      type: StoreActions.UPDATE_FORM_VALUES,
      payload: {
        landingPageForm: {
          ...state.formData,
          file: '',
        },
      },
    });
    setFile('');
    if (fileInputRef?.current?.value) {
      fileInputRef.current.value! = '';
    }
  };
  const onChannelSelect = (selectedChannel: ChannelTypes) => {
    if (state.formData.channel.length === 1) {
      if (!state.formData.channel.includes(selectedChannel)) {
        const newChannelArray = [...state.formData.channel, selectedChannel];
        dispatchChannelFormValue(newChannelArray);
      }
      return;
    }
    const indexOfSelectedChannel =
      state.formData.channel.indexOf(selectedChannel);
    if (indexOfSelectedChannel !== -1) {
      const channelArray = state.formData.channel;
      channelArray.splice(indexOfSelectedChannel, 1);
      dispatchChannelFormValue(channelArray);
    } else {
      dispatchChannelFormValue([...state.formData.channel, selectedChannel]);
    }
  };

  const dispatchChannelFormValue = (array: ChannelTypes[]) => {
    dispatch({
      type: StoreActions.UPDATE_FORM_VALUES,
      payload: {
        landingPageForm: {
          channel: array,
        },
      },
    });
  };
  return (
    <div className={style.page_container}>
      <Container className={style.header_container}>
        <h5 className={style.header}>Page content</h5>
      </Container>
      <div className={style.content_container}>
        <Container className={style.prime_flex_container}>
          <div
            className={`${style.file_drop_area} ${
              !state.formData.file ? style.drop_area_no_img : ''
            }`}
          >
            {state.formData.file ? (
              <>
                <IconButton
                  className={style.delete_button_container}
                  onClick={() => resetFileInput()}
                >
                  <Avatar
                    imgProps={{
                      className: style.avatar_icon,
                    }}
                    className={style.delete_icon}
                    src="assets/icons/delete-image.svg"
                  />
                </IconButton>
                <img
                  className={style.img}
                  alt="uploaded"
                  src={state.formData.file}
                />
              </>
            ) : (
              <img
                className={`${style.img} ${style.upload}`}
                alt="upload-icon"
                src={'assets/icons/upload-icon.svg'}
              />
            )}
            {!state.formData.file && (
              <span className={style.fake_btn}>Add an image [Optional]</span>
            )}
            <input
              onChange={($event) => handleSetNewFile($event.target.files?.[0])}
              className={style.file_input}
              ref={fileInputRef}
              type="file"
              accept="image/png, image/gif, image/jpeg"
            />
          </div>
        </Container>
        <Container className={style.bottom_section_container}>
          <CustomInput
            label="Headline"
            className={style.text_area}
            inputProps={{
              maxLength: 150,
              placeholder:
                'e.g. Where should I send your free social media checklist?',
            }}
            value={state.formData.description}
            multiline
            placeholder="e.g. Where should I send your free social media checklist?"
            rows={4}
            name="description"
            showCounter
          />
          <div className={style.channels_container}>
            <div className={style.channel_container_content}>
              <span className={style.channels_title}>Select channels</span>
              <span className={style.channels_desc}>
                How do you want to contact your users?
                {/* Select at least 1 channel */}
              </span>

              <div
                className={`${style.channels_select_container} ${
                  formErrors.channel ? style.error : ''
                }`}
              >
                {channels.map((item, index) => (
                  <div className={style.channel_option_container} key={index}>
                    <button
                      className={style.transparent_channel_button}
                      // disabled={item.disabled}
                      onClick={() => {
                        if (item.disabled) {
                          if (state.isLoggedIn) {
                            setDrawerOpen(true);
                          } else {
                            dispatch({
                              type: StoreActions.UPDATE_LOGIN_POPUP_STATE,
                              payload: {
                                isSingUpPopUp: true,
                              },
                            });
                            dispatch({
                              type: StoreActions.TOGGLE_LOGIN_POPUP,
                              payload: {
                                isRedirectedToView: true,
                              },
                            });
                          }
                        } else {
                          onChannelSelect(item.channelType);
                        }
                      }}
                    >
                      <img
                        className={style.channel_option_image}
                        src={item.svg}
                        alt=""
                      />
                    </button>
                    <span
                      className={`${style.channel_option_name} ${
                        state.formData.channel.includes(item.channelType)
                          ? style.bold
                          : ''
                      }`}
                    >
                      {item.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Container className={style.continue_button_container}>
        <Button
          className={style.continue_button}
          variant="contained"
          onClick={() => onContinueClicked()}
        >
          Continue
        </Button>
      </Container>
      <CustomDialog {...customDrawerProps} />
    </div>
  );
};
export default NewPageForm;
