import { AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosInstance from '../../utils/axios.instance';
import { ApiRoutes } from './api.routes';
import { ApiResponse } from './req.type';

// const { state, dispatch } = useAppContext();
/**
 * The function retrieves data from a specified URL and returns a Promise containing the data in an
 * ApiResponse object.
 * @param {string} url - A string representing the URL to which the HTTP GET request will be sent.
 * @returns A Promise that resolves to an ApiResponse object with the data property set to the response
 * data from the axios GET request.
 */
export function get<T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<ApiResponse<T>> {
  return axiosInstance.get(url, config).then((response: AxiosResponse<T>) => {
    return {
      data: response.data,
    };
  });
}

(async function getCsrfToken() {
  const response = await get<{ data: { token: string } }>(ApiRoutes.CSRF);

  const csrfToken = response.data;
  axiosInstance.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken.data.token;

  return csrfToken.data.token;
})();

/**
 * This function sends a POST request to a specified URL with a given body and returns a Promise
 * containing the response data.
 * @param {string} url - A string representing the URL to which the HTTP POST request will be sent.
 * @param {D} body - The `body` parameter is the data that will be sent in the request body when making
 * a POST request to the specified `url`. It is of type `D`, which means it can be any type of data
 * that the API endpoint expects to receive.
 * @returns A Promise that resolves to an object with a `data` property containing the response data of
 * type `T`. The response data is obtained by making a POST request to the specified `url` with the
 * provided `body` data of type `D`. The response is wrapped in an `ApiResponse` object.
 */
export function post<T, D>(url: string, body: D): Promise<ApiResponse<T>> {
  return axiosInstance.post(url, body).then((response: ApiResponse<T>) => {
    return {
      data: response.data,
    };
  });
}
