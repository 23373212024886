import Button from '@mui/material/Button';
import { RouterPaths } from '@passion/enums';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TrustBox from 'src/components/trustbox/Trustbox';
import style from './On-boarding.module.scss';
export const OnBoarding = () => {
  const navigate = useNavigate();
  const handleOnContinueClicked = () => {
    navigate(RouterPaths.CREATE_LANDING_PAGE);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={style.main_container}>
      <div className={`${style.section} ${style.vertical} ${style.top}`}>
        <div className={style.container}>
          <h1>Build a list of people who want to buy from you</h1>
          <p className={style.first_p}>
            Create a page in a few minutes that builds a list of emails, SMS,
            Whatsapp and Telegram contacts who are ready to buy.
          </p>
          <Button
            className={`${style.continue_button} ${style.cta_button}`}
            variant="contained"
            onClick={() => handleOnContinueClicked()}
          >
            Create Your Free Page
          </Button>
          <img
            className={style.image}
            src="https://uploads-ssl.webflow.com/606317d685e6b189218df406/64e74a2f21086db3a0801356_hero%20%204.webp"
            alt="Image 1"
          />
          <TrustBox />

          {/* <button
            onClick={() => (location.href = 'https://passion.io/')}
            className="cta-button"
          >
            Create Your Free Page
          </button> */}
        </div>
      </div>

      <div className={`${style.section} ${style.horizontal}`}>
        <div className={style.container}>
          <img
            className={style.image}
            src="https://uploads-ssl.webflow.com/606317d685e6b189218df406/64e74b15819e87ea60aadd40_how%20it%20works.webp"
            alt="Image 2"
          />
          <div>
            <h2>Turn followers into leads</h2>
            <ul>
              <li>Send a lead magnet to opt ins to increase trust</li>
              <li>Start high ticket sales conversations</li>
              <li>
                Break free from the algorithm and reach your audience any time
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={`${style.section} ${style.horizontal} ${style.reverse}`}>
        <div className={style.container}>
          <img
            className={style.image}
            src="https://uploads-ssl.webflow.com/606317d685e6b189218df406/64e74aa3dadbbc18879ec2df_QR%20code.webp"
            alt="Image 3"
          />
          <div>
            <h2>Build your list anytime, anywhere</h2>
            <ul>
              <li>Grab contacts speaking from stage</li>
              <li>Build hype for your product launch</li>
              <li>Share free resources or case studies</li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`${style.section} ${style.vertical}`}>
        <div className={style.steps_container}>
          <h2>Grow your list with a few taps</h2>

          <div className={style.steps_wrapper}>
            <div className={style.steps}>
              <div className={style.steps_title}>
                <p>
                  <b>1.</b> Claim your free page
                </p>
              </div>
              <img
                src="https://uploads-ssl.webflow.com/606317d685e6b189218df406/64e66a839a2ba65ebdbcb7ce_slide%201.webp"
                alt="slider 1"
                className={style.slider_image}
              />
            </div>
            <div className={style.steps}>
              <div className={style.steps_title}>
                <p>
                  <b>2.</b> Write your welcome message
                </p>
              </div>
              <img
                src="https://uploads-ssl.webflow.com/606317d685e6b189218df406/64e66a8395985a64cc34f38c_slide%202.webp"
                alt="slider 2"
                className={style.slider_image}
              />
            </div>
            <div className={style.steps}>
              <div className={style.steps_title}>
                <p>
                  <b>3.</b> Share your link
                </p>
              </div>
              <img
                src="https://uploads-ssl.webflow.com/606317d685e6b189218df406/64e66a85ca079fa649e64b40_slide%203.webp"
                alt="slider 3"
                className={`${style.slider_image} ${style.last_slider_image}`}
              />
            </div>
          </div>
          <Button
            className={style.continue_button}
            style={{ marginTop: '30px' }}
            variant="contained"
            onClick={() => handleOnContinueClicked()}
          >
            Create Your Free Page
          </Button>
          {/* <button
            onClick={() => (location.href = 'https://passion.io/')}
            className={style.cta_button}
          >
            Create Your Free Page
          </button> */}
        </div>
      </div>
      <div className={style.theme_footer}>
        <img
          src="https://assets-global.website-files.com/606317d685e6b189218df406/64254dff432e05940fae27e8_Passion.io%20Scribble.svg"
          loading="lazy"
          alt=""
          className={style.footer_scribble}
        />

        <div className={style.footer_links}>
          <a
            href="https://passion.io/privacy-policy"
            className={style.link_block}
          >
            Privacy Policy
          </a>
          <a
            href="https://passion.io/terms-of-service"
            className={style.link_block}
          >
            Terms of Service
          </a>
          <a href="https://passion.io/imprint" className={style.link_block}>
            Imprint
          </a>
          <div className={style.copyright_text}>
            2023 © All rights reserved for Passion.io
          </div>
        </div>
      </div>
    </div>
  );
};
export default OnBoarding;
