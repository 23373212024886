import { Avatar, Container, IconButton } from '@mui/material';
import { LpCard } from '@passion/components';
import { RouterPaths, StoreActions } from '@passion/enums';
import { ApiRoutes } from '@passion/services';
import { useAppContext } from '@passion/store';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'src/utils/axios.instance';
import style from './Home-page.module.scss';

const HomePage = () => {
  const { state, dispatch } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Build a list of people who want to buy from you';
  }, []);
  const handleCreateNewPage = () => {
    dispatch({
      type: StoreActions.RESET_FORM_DATA,
    });
    navigate(RouterPaths.CREATE_LANDING_PAGE);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getUserLandingPages();
  }, []);

  const getUserLandingPages = async () => {
    const response = await axiosInstance.get(`${ApiRoutes.PAGES}`);

    if (response.data) {
      dispatch({
        type: StoreActions.UPDATE_USER_LANDING_PAGES,
        payload: {
          userLandingPages: response.data.data,
        },
      });
    }
  };

  return (
    <Container className={style.page_container}>
      <Container className={style.content_container}>
        <Container className={style.top_page_content_container}>
          <h3 className={style.header}>Pages</h3>
          <Container className={style.download_icon_container}>
            <IconButton
              className={style.download_icon_button}
              onClick={handleCreateNewPage}
            >
              <Avatar
                sx={{ height: '20px', width: '20px' }}
                alt="download submissions icon"
                src="assets/icons/add.svg"
              />
            </IconButton>
          </Container>
        </Container>
        <Container className={style.lp_container}>
          {state.userLandingPages.length ? (
            state.userLandingPages.map((card, index) => (
              <LpCard key={`${card.id}-${index}`} {...card} />
            ))
          ) : (
            <div className={style.no_pages_container} />
          )}
        </Container>
      </Container>
    </Container>
  );
};
export default HomePage;
