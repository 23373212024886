import {
  Alert,
  AppBar,
  Avatar,
  Box,
  Container,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { RouterPaths, StoreActions } from '@passion/enums';
import { ApiRoutes } from '@passion/services';
import { useAppContext } from '@passion/store';
import { googleLogout } from '@react-oauth/google';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from 'src/utils/axios.instance';
import { CustomDialog, customDialogProps } from '..';
import style from './App-bar.module.scss';

function ResponsiveAppBar() {
  const backIcons = {
    arrowLeft: 'arrow-left',
    exit: 'exit',
  };
  const { state, dispatch } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [backIcon, setBackIcon] = useState(backIcons.arrowLeft);
  const [isBackIconVisible, setIsBackIconVisible] = useState(false);
  const [isAppBarVisible, setIsAppBarVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [customDrawerOpen, setCustomDrawerOpen] = useState(false);

  const customDrawerProps: customDialogProps = {
    open: customDrawerOpen,
    title: 'Something went wrong',
    subtitle:
      'The action you took could not be performed. Please wait a moment and then reload the page to see if the problem persists.',
    actions: [],
    imgPath: 'assets/icons/global-error.svg',
    close: () => {
      dispatchCloseErrorDialog();
    },
    showBackArrow: true,
  };

  const dispatchCloseErrorDialog = () => {
    dispatch({
      type: StoreActions.GLOBAL_ERROR_STATE,
      payload: { globalErrorState: false },
    });
  };

  useEffect(() => {
    setCustomDrawerOpen(state.globalErrorState);
  }, [state.globalErrorState]);

  useEffect(() => {
    // setTimeout(() => {
    //   const path = location.pathname;
    //   if (
    //     !path.includes(RouterPaths.HOME) &&
    //     !id &&
    //     !path.includes(RouterPaths.CREATE_LANDING_PAGE) &&
    //     !path.includes(RouterPaths.SUBMISSIONS_PAGE) &&
    //     path !== RouterPaths.ON_BOARDING
    //   ) {
    //     const isSame = Object.keys(state.formData).filter((_key: string) => {
    //       if (_key === 'channel') {
    //         if (
    //           state.formData.channel.length !== defaultFormData.channel.length
    //         ) {
    //           return false;
    //         }
    //         return (
    //           state.formData.channel.toString() !==
    //           defaultFormData.channel.toString()
    //         );
    //       }
    //       return (
    //         state.formData[_key as keyof landingPageForm] !==
    //         defaultFormData[_key as keyof landingPageForm]
    //       );
    //     });
    //     if (!isSame.length) {
    //       navigate(RouterPaths.ON_BOARDING);
    //     }
    //   }
    // }, 2000);
  }, []);

  useEffect(() => {
    setIsBackIconVisible(
      location.pathname !== RouterPaths.HOME &&
        location.pathname !== RouterPaths.ON_BOARDING,
    );

    if (location.pathname === RouterPaths.CREATE_LANDING_PAGE) {
      setBackIcon(backIcons.exit);
    } else {
      if (backIcon !== backIcons.arrowLeft) {
        setBackIcon(backIcons.arrowLeft);
      }
    }
    setIsAppBarVisible(
      !location.pathname.includes(RouterPaths.LANDING_PAGE_PREVIEW) && !id,
    );
  }, [backIcon, backIcons.arrowLeft, backIcons.exit, id, location.pathname]);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const openLogInPopUp = () => {
    dispatch({
      type: StoreActions.UPDATE_LOGIN_POPUP_STATE,
      payload: {
        isSingUpPopUp: false,
      },
    });
    dispatch({ type: StoreActions.TOGGLE_LOGIN_POPUP });
  };

  const handlePassionIconClick = () => {
    if (state.isLoggedIn) {
      navigate(RouterPaths.HOME);
    } else {
      navigate(RouterPaths.ON_BOARDING);
    }
  };

  const handleBackCLick = () => {
    navigate(-1);
  };

  const handleLogout = () => {
    googleLogout();
    if (state.isLoggedIn) {
      dispatch({ type: StoreActions.TOGGLE_LOGIN });
    }
    handleCloseUserMenu();
    axiosInstance.get(ApiRoutes.LOGOUT);
    setOpen(true);
    dispatch({ type: StoreActions.RESET_FORM_DATA });
    navigate(RouterPaths.ON_BOARDING);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      {isAppBarVisible && (
        <AppBar
          className={`${style.float} ${
            location.pathname === '/' ? style.black : ''
          } ${style.app_bar}`}
          position="static"
        >
          <Container maxWidth="xl" className={style.toolbar}>
            <Toolbar disableGutters>
              {state.isLoggedIn && isBackIconVisible && (
                <Box sx={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      display: { xs: 'flex', md: 'flex' },
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      onClick={handleBackCLick}
                      sx={{ p: 0 }}
                      disabled={!state.isLoggedIn || !isBackIconVisible}
                    >
                      <Avatar
                        className={isBackIconVisible ? '' : style.no_back_icon}
                        sx={{ height: '20px', width: '20px' }}
                        alt="Remy Sharp"
                        src={
                          isBackIconVisible
                            ? `assets/icons/${backIcon}.svg`
                            : ''
                        }
                      />
                    </IconButton>
                  </Box>
                </Box>
              )}
              <button
                className={style.app_bar_logo_container}
                onClick={handlePassionIconClick}
              >
                <img
                  src={
                    location.pathname === '/'
                      ? 'https://uploads-ssl.webflow.com/606317d685e6b189218df406/64e73dabeb6715a723c7daff_PassionPages%20White.svg'
                      : 'assets/icons/PassionPages.png'
                  }
                  alt=""
                  style={{ height: '24px' }}
                />
              </button>

              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} />

              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />

              <Box sx={{ flexGrow: 0 }}>
                <Box
                  sx={{
                    display: { xs: 'flex', md: 'flex' },
                    alignItems: 'center',
                  }}
                >
                  {state.isLoggedIn ? (
                    <Tooltip
                      title="User Menu"
                      className="user-logo"
                      aria-label={state.user?.picture}
                    >
                      <IconButton
                        onClick={handleOpenUserMenu}
                        sx={{ p: 0 }}
                        disabled={!state.isLoggedIn}
                      >
                        <Avatar
                          sx={{
                            height: state.user?.picture ? '25px' : '20px',
                            width: state.user?.picture ? '25px' : '20px',
                          }}
                          style={{
                            background: 'red',
                            borderRadius: '20%',
                          }}
                          src={
                            state.user?.picture || 'assets/icons/iOS_login.svg'
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Avatar
                      sx={{
                        height: '20px',
                        borderRadius: location.pathname === '/' ? '0%' : 'auto',
                        minWidth: location.pathname === '/' ? '60px' : '20px',
                        width: location.pathname === '/' ? 'auto' : '20px',
                        cursor: 'pointer',
                      }}
                      onClick={() => openLogInPopUp()}
                      style={{ borderRadius: 'none' }}
                      alt="Remy Sharp"
                      src={
                        location.pathname === '/'
                          ? 'https://uploads-ssl.webflow.com/606317d685e6b189218df406/64e6554c0b637d796e40705d_Log%20in%20white.svg'
                          : 'assets/icons/login-icon.svg'
                      }
                    />
                  )}
                  {!state.isLoggedIn && location.pathname !== '/' ? (
                    <button
                      className={style.login_text}
                      onClick={() => openLogInPopUp()}
                    >
                      Login
                    </button>
                  ) : (
                    <></>
                  )}
                </Box>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem
                    onClick={() => navigate(RouterPaths.HOME)}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '8px 12px',
                    }}
                  >
                    <Icon>
                      <Avatar
                        sx={{ height: '20px', width: '20px', padding: '0 5px' }}
                        alt="Remy Sharp"
                        src="assets/icons/dashboard.svg"
                      />
                    </Icon>
                    <Typography style={{ paddingLeft: '10px' }}>
                      Dashboard
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={handleLogout}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '8px 12px',
                    }}
                  >
                    <Icon>
                      <Avatar
                        sx={{ height: '20px', width: '20px', padding: '0 5px' }}
                        alt="Remy Sharp"
                        src="assets/icons/logout.svg"
                      />
                    </Icon>
                    <Typography style={{ paddingLeft: '10px' }}>
                      Logout
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      )}
      <Outlet />
      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        classes={{
          anchorOriginBottomLeft: style.snackbar_container,
        }}
      >
        <Alert
          severity="success"
          sx={{ width: '100%' }}
          classes={{
            standardSuccess: style.snackbar_success,
          }}
          icon={<img src="assets/icons/success.svg" />}
        >
          Logout success
        </Alert>
      </Snackbar>
      <CustomDialog {...customDrawerProps} />
    </>
  );
}
export default ResponsiveAppBar;
