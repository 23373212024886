export enum StoreActions {
  TOGGLE_LOGIN = 'TOGGLE_LOGIN',
  TOGGLE_LOGIN_POPUP = 'TOGGLE_LOGIN_POPUP',
  UPDATE_LOGIN_STATE = 'UPDATE_LOGIN_STATE',
  UPDATE_FORM_VALUES = 'UPDATE_FORM_VALUES',
  VALIDATE_FORM = 'VALIDATE_FORM',
  UPDATE_FORM_ERRORS = 'UPDATE_FORM_ERRORS',
  UPDATE_CSRF_TOKEN = 'UPDATE_CSRF_TOKEN',
  UPDATE_PAGE_SUBMISSIONS = 'UPDATE_PAGE_SUBMISSIONS',
  UPDATE_USER_LANDING_PAGES = 'UPDATE_USER_LANDING_PAGES',
  RESET_FORM_DATA = 'RESET_FORM_DATA',
  START_REDIRECT_PROCCESS = 'START_REDIRECT_PROCCESS',
  UPLOAD_IMAGE = 'UPLOAD_IMAGE',
  TOGGLE_LOADING = 'TOGGLE_LOADING',
  UPDATE_USER = 'UPDATE_USER',
  GLOBAL_ERROR_STATE = 'GLOBAL_ERROR_STATE',
  UPDATE_LOGIN_POPUP_STATE = 'UPDATE_LOGIN_POPUP_STATE',
}
