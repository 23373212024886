import { useAppContext } from '@passion/store';
import style from './loader.module.scss';
const Loader = () => {
  const { state, dispatch } = useAppContext();

  return state.loading ? (
    <div className={style.loader}>
      <div className={style.dot} />
      <div className={style.dot} />
      <div className={style.dot} />
    </div>
  ) : null;
};

export default Loader;
