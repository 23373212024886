import {
  Avatar,
  Container,
  IconButton,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { LpActions } from '@passion/components';
import { RouterPaths, StoreActions } from '@passion/enums';
import { useAppContext } from '@passion/store';
import { lpCardProps } from '@passion/types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './Lp-card.module.scss';

interface erroredImg {
  src?: string;
  error: boolean;
}
export const LpCard: React.FC<lpCardProps> = (props: lpCardProps) => {
  const { channel, id, description, file, submissionMessage, url, views } =
    props;
  const { dispatch } = useAppContext();
  const navigate = useNavigate();
  const [imageErrored, setImageErrored] = useState<erroredImg>({
    error: file?.length ? false : true,
    src: file?.length ? file : 'assets/icons/no-image.svg',
  });

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const handleImgError = () => {
    setImageErrored({
      src: 'assets/icons/no-image.svg',
      error: true,
    });
  };

  const handleToggleDrawer = (open: boolean, event: any) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const handlePreviewPage = () => {
    dispatch({
      type: StoreActions.UPDATE_FORM_VALUES,
      payload: {
        landingPageForm: {
          ...props,
        },
      },
    });
    navigate(RouterPaths.LANDING_PAGE_PREVIEW + '/' + url + '?edit=true', {
      // state: {
      //   edit: true,
      // },
    });
  };

  return (
    <Container className={style.lp_card_container}>
      <Container
        className={`${style.image_container} ${
          imageErrored.error ? style.image_error_container : ''
        }`}
      >
        <img
          className={`${style.img} ${
            imageErrored.error ? style.img_error : ''
          }`}
          onClick={(event) => handlePreviewPage()}
          src={imageErrored.src}
          alt=""
          onError={handleImgError}
          onEmptied={handleImgError}
        />
        <IconButton
          className={style.more_button}
          onClick={($event) => {
            handleToggleDrawer(true, $event);
          }}
        >
          <Avatar src="assets/icons/more.svg" className={style.more_icon} />
        </IconButton>
      </Container>
      <Typography className={style.title}>{description}</Typography>
      <>
        <SwipeableDrawer
          anchor="bottom"
          open={drawerOpen}
          onClose={($event) => handleToggleDrawer(false, $event)}
          onOpen={($event) => handleToggleDrawer(true, $event)}
          className={style.drawer}
          classes={{
            paper: style.drawer_paper,
          }}
        >
          <Container className={style.drawer_content_container}>
            <LpActions {...props} />
          </Container>
        </SwipeableDrawer>
      </>
    </Container>
  );
};

export default LpCard;
