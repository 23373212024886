import { Button, Container, InputAdornment } from '@mui/material';

import {
  CustomDialog,
  CustomInput,
  customDialogProps,
} from '@passion/components';
import { ChannelTypes, RouterPaths, StoreActions } from '@passion/enums';
import { ApiRoutes } from '@passion/services';
import { useAppContext } from '@passion/store';
import { landingPageForm } from '@passion/types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from 'src/utils/axios.instance';
import style from './Settings-page.module.scss';

type CloudinaryImage = {
  public_id: string;
  url: string;
};

export const SettingsPage = () => {
  const { state, dispatch } = useAppContext();
  const [description, setDescription] = useState<string>('');
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [url, setUrl] = useState<string>(state.formData.url || '');
  const [isLoginByDomainProccess, setIsLoginByDomainProccess] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const { edit = false } = useLocation()?.state || {};
  const [isEditMode, setIsEditMode] = useState(false);
  const customDrawerProps: customDialogProps = {
    imgPath: 'assets/icons/email-marketing.gif',
    open: drawerOpen,
    title: 'Coming soon!',
    style: { maxWidth: '60%' },
    subtitle: 'We’ll email you when it’s ready',
    actions: [
      {
        text: 'ok',
        backgroundColor: '#000000',
        color: '#FFFFFF',
        action: () => {
          setDrawerOpen(false);
        },
      },
    ],
  };
  const [currentUrl, setCurrentUrl] = useState(state.formData.url || '');
  const [error, setError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (!currentUrl) {
      setCurrentUrl(state.formData.url?.toLowerCase() || '');
    }
    setUrl(state.formData.url?.toLowerCase() || '');
  }, [state.formData]);
  /* The `useEffect` hook is used to perform side effects in a functional component. In this case, the
effect is triggered whenever the `url` state variable changes.
the effect check if the `url` state variable is empty or not. If it is empty, it will redirect ,
if not , it will validate the url and check for availability
*/
  useEffect(() => {
    let handleUrlValidation: NodeJS.Timeout;
    setIsEditMode(edit);

    handleUrlValidation = setTimeout(() => {
      if (url.length) {
        if (!url?.match(RegExp(/^[a-zA-Z0-9]+[a-zA-Z0-9-._]*[a-zA-Z0-9]+$/))) {
          dispatch({
            type: StoreActions.UPDATE_FORM_ERRORS,
            payload: {
              error: {
                formFieldName: 'url',
                message: 'Invalid url',
              },
            },
          });
          return;
        }
        validateUrlAvailability(url.toLowerCase());
      }
    }, 1000);
    return () => clearTimeout(handleUrlValidation);
  }, [url]);

  useEffect(() => {
    if (state.startRedirectProccess && state.isLoggedIn) {
      if (isLoginByDomainProccess) {
        handleOnUseYourOwnDomainClick();
      } else {
        handleOnContinueClicked();
      }
    }
  }, [state.startRedirectProccess]);

  const validateUrlAvailability = async (url: string) => {
    const isRequestedUrlAvailable = await (
      await axiosInstance.get(
        `${ApiRoutes.IS_REQUESTED_URL_AVAILABLE}/${url.toLowerCase()}`,
      )
    ).data;

    if (!isRequestedUrlAvailable.isSuccess && currentUrl !== url) {
      dispatch({
        type: StoreActions.UPDATE_FORM_ERRORS,
        payload: {
          error: {
            formFieldName: 'url',
            message: 'url is already in use',
          },
        },
      });
      setError(true);
      return false;
    } else {
      // dispatch({
      //   type: StoreActions.UPDATE_FORM_VALUES,
      //   payload: {
      //     landingPageForm: {
      //       ...state.formData,
      //       url: url.toLowerCase(),
      //     },
      //   },
      // });
      setError(false);
      dispatch({
        type: StoreActions.UPDATE_FORM_ERRORS,
      });
      return true;
    }
  };

  const handleOnContinueClicked = async () => {
    const isRequiredFieldsValid = !!state.formData.url;

    if (state.isLoggedIn && isRequiredFieldsValid) {
      if (!state.formData.id) {
        const isUrlAvailable = await validateUrlAvailability(
          state.formData.url?.toLowerCase() || '',
        );
        if (!isUrlAvailable) {
          return;
        }
      }
      try {
        await handleCreationProccess();
      } catch (error) {
        console.log(error);
      }
      dispatch({
        type: StoreActions.UPDATE_FORM_ERRORS,
      });
      dispatch({
        type: StoreActions.START_REDIRECT_PROCCESS,
        payload: {
          startRedirectProccess: false,
        },
      });
      navigate(RouterPaths.LANDING_PAGE_PREVIEW + '/' + url + '?edit=true', {
        state: {
          edit: true,
        },
      });
    } else {
      if (!isRequiredFieldsValid) {
        return;
      }
      dispatch({
        type: StoreActions.UPDATE_LOGIN_POPUP_STATE,
        payload: {
          isSingUpPopUp: true,
        },
      });
      dispatch({
        type: StoreActions.TOGGLE_LOGIN_POPUP,
        payload: {
          isRedirectedToView: true,
        },
      });
    }
  };

  const handleCreationProccess = async () => {
    let page;

    if (edit) {
      page = await axiosInstance.patch(
        `${ApiRoutes.PAGES}/${state.formData.id}`,
        { ...state.formData, url: state.formData.url?.toLowerCase() },
      );
    } else {
      page = await axiosInstance.post(ApiRoutes.PAGES, state.formData);
    }
    dispatch({
      type: StoreActions.UPDATE_FORM_VALUES,
      payload: {
        landingPageForm: {
          ...state.formData,
          id: page.data.data.id,
          url,
        },
      },
    });
  };

  const handleUseYourOnDomainClick = () => {
    setDrawerOpen(true);
  };

  const handleDescription = (value: string) => {
    dispatch({
      type: StoreActions.UPDATE_FORM_VALUES,
      payload: {
        landingPageForm: {
          ...state.formData,
          submissionMessage: value.toLowerCase(),
        },
      },
    });
  };

  const handleOnUseYourOwnDomainClick = () => {
    if (state.isLoggedIn && !drawerOpen) {
      setDrawerOpen(true);
    } else {
      dispatch({
        type: StoreActions.UPDATE_LOGIN_POPUP_STATE,
        payload: {
          isSingUpPopUp: true,
        },
      });
      dispatch({
        type: StoreActions.TOGGLE_LOGIN_POPUP,
        payload: {
          isRedirectedToView: true,
        },
      });
      setIsLoginByDomainProccess(true);
    }
  };

  const isUrInputError = (): boolean => {
    if (state.formErrors.url.length === 0) {
      return false;
    }
    if (edit) {
      return currentUrl !== state.formData.url;
    }
    return true;
  };

  const handleSubject = (subject: string) => {
    dispatch({
      type: StoreActions.UPDATE_FORM_VALUES,
      payload: {
        landingPageForm: {
          ...state.formData,
          subject: subject,
        },
      },
    });
  };

  return (
    <div className={style.page_container}>
      <div className={style.content_container}>
        <Container className={style.content_mui_container}>
          <Container className={style.top_page_container}>
            <h3 className={style.header}>Page settings</h3>
            <div className={style.top_inputs_container}>
              <CustomInput
                error={error}
                errorMessage={state.formErrors.url}
                label="Url"
                className={`${style.url_field} ${style.text_area}`}
                inputProps={{
                  maxLength: 150,
                  style: { textTransform: 'lowercase' },
                }}
                value={state.formData.url}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={style.input_adornment}
                    >
                      {import.meta.env.VITE_LANDING_DOMAIN}/
                    </InputAdornment>
                  ),
                  placeholder: 'your-url-here',
                }}
                rows={4}
                name="url"
              />
              <Button
                onClick={handleOnUseYourOwnDomainClick}
                className={style.custom_domain_button}
              >
                Use your own domain
              </Button>
            </div>
          </Container>

          <h5 className={`${style.header} ${style.submission}`}>Messaging</h5>
          {state.formData.channel.includes(ChannelTypes.EMAIL) && (
            <div className={style.subject_input}>
              <CustomInput
                label="Email Subject"
                className={style.text_area}
                onChange={($event) => handleSubject($event.target.value)}
                value={state.formData.subject}
                inputProps={{
                  maxLength: 50,
                  placeholder: `e.g. Your Free Video`,
                }}
                rows={2}
                multiline
                placeholder={`e.g. Your Free Video`}
                name="subject"
                showCounter
              />
            </div>
          )}
          <div className={style.textarea_container}>
            {!!description?.length && (
              <span className={style.textarea_counter}>
                {description.length}
              </span>
            )}
            <CustomInput
              label="Welcome message"
              className={style.text_area}
              onChange={($event) => handleDescription($event.target.value)}
              inputProps={{
                maxLength: 250,
                placeholder: `e.g. Thanks! Here's a link to your free social media check list: passion.io/social-media`,
              }}
              value={state.formData.submissionMessage}
              multiline
              placeholder={`e.g. Thanks! Here's a link to your free social media check list: passion.io/social-media`}
              rows={4}
              name="submissionMessage"
              showCounter
            />
          </div>
          <Button
            className={`${style.continue_button} ${style.last_settings_page_element}`}
            variant="contained"
            disabled={
              !!Object.keys(state.formErrors).find(
                (key: keyof any) =>
                  state.formErrors[key as keyof landingPageForm].length !== 0,
              ) && !edit
            }
            onClick={() => handleOnContinueClicked()}
          >
            View
          </Button>
        </Container>
      </div>
      <CustomDialog {...customDrawerProps} />
    </div>
  );
};
export default SettingsPage;
