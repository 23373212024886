import { ChannelTypes } from '@passion/enums';
import { Submission, lpCardProps } from '@passion/types';
import React, { createContext, useContext, useReducer } from 'react';
import { StoreActions } from '../enums/store-enum';
import { StoreAction, landingPageForm } from '../types/store-type';
import { checkInterface } from '../utils/landing-page-form-interface-validator';

interface AppState {
  isLoggedIn: boolean;
  isSignUpRegisterPopUpOpen: boolean;
  formData: landingPageForm;
  formErrors: Record<keyof landingPageForm, string>;
  userLandingPages: lpCardProps[];
  csrfToken?: string;
  landingPageSubmission: Submission[];
  isRedirectedToView: boolean;
  startRedirectProccess: boolean;
  image?: File;
  loading: boolean;
  user?: { email: string; picture?: string };
  globalErrorState: boolean;
  isSingUpPopUp: boolean;
}
export const defaultFormData: landingPageForm = {
  channel: [ChannelTypes.EMAIL],
  description: '',
  submissionMessage: '',
};

const initialState: AppState = {
  isLoggedIn: false,
  isSignUpRegisterPopUpOpen: false,
  formData: {
    channel: [ChannelTypes.EMAIL],
    description: '',
    submissionMessage: '',
  },
  formErrors: {
    channel: '',
    description: '',
    file: '',
    submissionMessage: '',
    url: '',
    id: '',
    image_id: '',
    subject: '',
  },
  userLandingPages: [],
  landingPageSubmission: [],
  isRedirectedToView: false,
  startRedirectProccess: false,
  image: undefined,
  loading: false,
  globalErrorState: false,
  isSingUpPopUp: false,
};

interface AppContextProps {
  state: AppState;
  dispatch: React.Dispatch<StoreAction>;
}
const AppContext = createContext<AppContextProps | undefined>(undefined);

export const useAppContext = (): AppContextProps => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

const appReducer = (state: AppState, action: StoreAction): AppState => {
  switch (action.type) {
    case StoreActions.TOGGLE_LOGIN:
      const isLoggedIn = action.payload?.isLoggedIn || !state.isLoggedIn;
      if (state.isLoggedIn !== isLoggedIn) {
        return { ...state, isLoggedIn: isLoggedIn };
      }
      return { ...state };
    case StoreActions.TOGGLE_LOGIN_POPUP:
      return {
        ...state,
        isSignUpRegisterPopUpOpen: !state.isSignUpRegisterPopUpOpen,
        isRedirectedToView: !!action.payload?.isRedirectedToView,
      };
    case StoreActions.UPDATE_FORM_VALUES:
      return {
        ...state,
        formData:
          action.payload?.landingPageForm &&
          checkInterface(action.payload.landingPageForm)
            ? { ...state.formData, ...action.payload.landingPageForm }
            : state.formData,
      };
    case StoreActions.VALIDATE_FORM:
      return {
        ...state,
        formErrors: validateFormData(
          state.formData,
          !checkInterface(action.payload?.landingPageForm)
            ? action.payload?.landingPageForm
            : undefined,
        ),
      };
    case StoreActions.UPDATE_FORM_ERRORS:
      return {
        ...state,

        formErrors: action.payload
          ? {
              ...state.formErrors,
              [action.payload?.error?.formFieldName || '']:
                action.payload?.error?.message || '',
            }
          : initialState.formErrors,
      };
    case StoreActions.UPDATE_CSRF_TOKEN:
      return {
        ...state,
        csrfToken: action.payload?.csrfToken || '',
      };
    case StoreActions.UPDATE_PAGE_SUBMISSIONS: {
      return {
        ...state,
        landingPageSubmission: action.payload?.landingPageSubmission || [],
      };
    }
    case StoreActions.UPDATE_USER_LANDING_PAGES: {
      return {
        ...state,
        userLandingPages: action.payload?.userLandingPages || [],
      };
    }
    case StoreActions.RESET_FORM_DATA: {
      return {
        ...state,
        formData: initialState.formData,
      };
    }
    case StoreActions.START_REDIRECT_PROCCESS:
      return {
        ...state,
        startRedirectProccess: !!action.payload?.startRedirectProccess,
      };
    case StoreActions.UPLOAD_IMAGE: {
      return {
        ...state,
        image: action.payload?.image,
      };
    }
    case StoreActions.TOGGLE_LOADING: {
      const mappedState = action.payload?.loading || !state.loading;

      return {
        ...state,
        loading:
          typeof action.payload?.loading === 'boolean'
            ? action.payload.loading
            : !state.loading,
      };
    }
    case StoreActions.UPDATE_USER: {
      return {
        ...state,
        user: action.payload?.user || undefined,
      };
    }

    case StoreActions.GLOBAL_ERROR_STATE: {
      return {
        ...state,
        globalErrorState: action.payload?.globalErrorState || false,
      };
    }

    case StoreActions.UPDATE_LOGIN_POPUP_STATE: {
      return {
        ...state,
        isSingUpPopUp: !!action.payload?.isSingUpPopUp,
      };
    }
    default:
      return state;
  }
};

const validateFormData = (
  form: landingPageForm,
  currentFormErrors?: Record<keyof landingPageForm, string>,
): Record<keyof landingPageForm, string> => {
  const formErrors = currentFormErrors || {
    channel: '',
    description: '',
    file: '',
    submissionMessage: '',
    url: '',
    id: '',
    image_id: '',
    subject: '',
  };
  return formErrors;
};

export const AppProvider: React.FC<{ children: any }> = ({ children }: any) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const contextValue: AppContextProps = {
    state,
    dispatch,
  };
  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
