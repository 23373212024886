import {
  Avatar,
  Button,
  Container,
  IconButton,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import styles from './Custom-dialog.module.scss';
export interface customDialogProps {
  imgPath?: string;
  title: string;
  subtitle: string;
  actions: {
    backgroundColor: string;
    color: string;
    action: Function;
    text: string;
    border?: string;
  }[];
  open: boolean;
  style?: { [key: string]: any };
  close?: Function;
  showBackArrow?: boolean;
}

export const CustomDialog = ({
  imgPath,
  title,
  subtitle,
  actions,
  open,
  style,
  close,
  showBackArrow,
}: customDialogProps) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      onOpen={() => console.log('open')}
      onClose={() => () => (close ? close() : console.log('close'))}
      open={open}
      className={styles.drawer}
      classes={{
        paper: styles.drawer_paper,
      }}
    >
      <Container className={styles.drawer_content_container}>
        {showBackArrow && (
          <Container className={styles.back_arrow}>
            <IconButton
              className={styles.back_button}
              onClick={() => (close ? close() : null)}
            >
              <Avatar
                src="assets/icons/arrow-left.svg"
                style={{ height: '20px', width: '20px' }}
              />
            </IconButton>
          </Container>
        )}
        {imgPath && (
          <Container className={styles.image_container}>
            <img src={imgPath} alt="" style={style} />
          </Container>
        )}
        <Typography className={styles.title}>{title}</Typography>
        <Typography className={styles.subtitle}>{subtitle}</Typography>
        <Container className={styles.actions_container}>
          {actions.map((item, index) => (
            <Button
              key={index}
              className={styles.action_button}
              onClick={() => item.action()}
              style={{
                backgroundColor: item.backgroundColor,
                color: item.color,
                border: item.border || 'none',
                cursor: 'pointer',
              }}
            >
              {item.text}
            </Button>
          ))}
        </Container>
      </Container>
    </SwipeableDrawer>
  );
};

export default CustomDialog;
