import {
  Container,
  FilledInputProps,
  InputProps,
  OutlinedInputProps,
  TextField,
} from '@mui/material';
import { StoreActions } from '@passion/enums';
import { useAppContext } from '@passion/store';
import { landingPageForm } from '@passion/types';
import { MuiTelInput, MuiTelInputInfo } from 'mui-tel-input';
import { ReactElement, useRef, useState } from 'react';
import style from './Custom-input.module.scss';

interface customInputProps extends InputProps {
  standAlone?: boolean;
  name: keyof landingPageForm;
  label: string;
  errorMessage?: string;
  showCounter?: boolean;
  InputProps?:
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | Partial<InputProps>
    | undefined;
  validateInput?: ($event: any) => any;
  isPhoneNumberInput?: boolean;
  onChangeMobileInput?: Function;
  error?: boolean;
}

export const CustomInput = (props: customInputProps) => {
  const { state, dispatch } = useAppContext();
  const textAreaRef = useRef<HTMLInputElement | null>(null);

  const [phoneValue, setPhoneValue] = useState('');

  const onChangeHandler = (value: string) => {
    if (props.standAlone) {
      return value;
    }
    const newState = { ...state.formData, [props.name]: value };
    if (props.name === 'url') {
      newState.url = value.toLowerCase();
    }
    if (props.validateInput === undefined) {
      dispatchUpdateFormAction(newState);
    }
    if (props.validateInput !== undefined && props.validateInput(value)) {
      dispatchUpdateFormAction(newState);
    } else {
    }
  };

  const dispatchUpdateFormAction = (landingPageFormObject: landingPageForm) => {
    dispatch({
      type: StoreActions.UPDATE_FORM_VALUES,
      payload: {
        landingPageForm: landingPageFormObject,
      },
    });
  };

  const onPhoneChangeHandler = (value: string, info: MuiTelInputInfo) => {
    textAreaRef.current!.focus();
    setPhoneValue(value);
    props?.onChangeMobileInput?.(info);
  };

  const ContentController = (content: ReactElement) => {
    if (props.showCounter) {
      return (
        <Container
          className={`${
            props.name === 'description' ? style.desc : style.textarea_container
          } ${props.className}`}
        >
          <span
            className={`${style.textarea_counter} ${
              !!state.formData[props.name]?.length ? '' : style.greyed
            } ${props.name === 'description' ? style.desc_input : ''} `}
          >
            {props.inputProps?.maxLength -
              (state.formData![props.name]?.length || 0)}
          </span>
          {content}
        </Container>
      );
    }
    return content;
  };
  return props.isPhoneNumberInput
    ? ContentController(
        <MuiTelInput
          onChange={onPhoneChangeHandler}
          value={phoneValue}
          forceCallingCode
          defaultCountry={'US'}
          InputProps={props.InputProps}
          inputProps={props.inputProps}
          inputRef={textAreaRef}
          required
          className={`${style.text_area} ${style.phone_number}`}
          onKeyDown={(key) =>
            props.onKeyDown ? props.onKeyDown(key as any) : null
          }
        />,
      )
    : ContentController(
        <TextField
          type="text"
          error={props.error}
          helperText={
            props.error
              ? props.errorMessage || state.formErrors[props.name]
              : ''
          }
          onChange={($event) =>
            props.standAlone
              ? props?.onChange?.($event)
              : onChangeHandler($event.target.value)
          }
          onKeyDown={(key) =>
            props.onKeyDown ? props.onKeyDown(key as any) : null
          }
          rows={props.rows}
          value={props.value}
          name={props.name}
          InputLabelProps={{ shrink: true }}
          multiline={props.multiline}
          inputProps={props.inputProps}
          InputProps={props.InputProps}
          label={props.label}
          className={`${style.text_area} ${props.className}`}
          onBlur={(event) => (props?.onBlur ? props.onBlur(event) : null)}
          variant="outlined"
          placeholder={props.placeholder}
          disabled={props.disabled}
        />,
      );
};

export default CustomInput;
