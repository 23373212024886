import {
  Alert,
  Avatar,
  Container,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material';
import { Submission, SumCard } from '@passion/components';
import { useAppContext } from '@passion/store';
import { sumCardProps } from '@passion/types';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import style from './Submission-page.module.scss';

const SubmissionPage = () => {
  const { state, dispatch } = useAppContext();
  const [open, setOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const location = useLocation();

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleDownloadSubmissions = () => {
    const sorted = [...state.landingPageSubmission]
      .map(({ contactInfo, date, type }) => [contactInfo, date, type].join(','))
      .join('\n');

    const csvContent = ['contact,date,type', sorted].join('\n');

    const csvBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    saveAs(csvBlob);
    setSnackbarText('Submission details downloaded!');
    setOpen(true);
  };

  const onCopyToClipboard = () => {
    setSnackbarText('Details copied to clipboard!');
    setOpen(true);
  };

  const sumContainerArray: sumCardProps[] = [
    {
      iconPath: 'assets/icons/total-visits.svg',
      alt: 'total visits icon',
      sum:
        state.userLandingPages.find((page) => page.id === location?.state.id)
          ?.views || '0',
      desc: 'Total visits',
    },
    {
      iconPath: 'assets/icons/total-submissions.svg',
      alt: 'total submissions icon',
      sum: state.landingPageSubmission.length.toString(),
      desc: 'Total submissions',
    },
  ];

  return (
    <Container className={style.page_container}>
      <Container className={style.content_container}>
        <Container className={style.top_page_content_container}>
          <h3 className={style.header}>Submissions</h3>
          <Container className={style.download_icon_container}>
            <IconButton
              className={style.download_icon_button}
              onClick={handleDownloadSubmissions}
            >
              <Avatar
                className={style.avatar_icon}
                sx={{ height: '20px', width: '20px' }}
                alt="download submissions icon"
                src="assets/icons/download.svg"
              />
            </IconButton>
          </Container>
        </Container>

        <Container className={style.sum_container}>
          {sumContainerArray.map(({ iconPath, alt, sum, desc }, index) => (
            <SumCard
              key={index}
              iconPath={iconPath}
              alt={alt}
              sum={sum}
              desc={desc}
            />
          ))}
        </Container>
        <Container className={style.submission_card_container}>
          {state.landingPageSubmission.length ? (
            state.landingPageSubmission.map((submission, index) => (
              <Submission
                key={index}
                contactInfo={submission.contactInfo}
                date={submission.date}
                iconPath={`assets/icons/submission-${submission.type}.svg`}
                type={submission.type}
                onCopy={onCopyToClipboard}
              />
            ))
          ) : (
            <Typography>No Submissions Yet</Typography>
          )}
        </Container>
      </Container>
      <>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          classes={{
            anchorOriginBottomLeft: style.snackbar_container,
          }}
        >
          <Alert
            // onClose={handleClose}
            severity="success"
            sx={{ width: '100%' }}
            classes={{
              standardSuccess: style.snackbar_success,
            }}
            icon={<img src="assets/icons/success.svg" />}
          >
            {snackbarText}
          </Alert>
        </Snackbar>
      </>
    </Container>
  );
};
export default SubmissionPage;
