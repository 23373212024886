import { Avatar, Container, Typography } from '@mui/material';
import { CustomDialog, customDialogProps } from '@passion/components';
import { RouterPaths, StoreActions } from '@passion/enums';
import { ApiRoutes } from '@passion/services';
import { useAppContext } from '@passion/store';
import { lpCardProps } from '@passion/types';
import { useId, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'src/utils/axios.instance';
import style from './lp-actions.module.scss';

interface landingPageActions {
  iconPath: string;
  action: Function;
  descriptions: string;
  color?: string;
}

export const LpActions = (props: lpCardProps) => {
  const { channel, id, description, file, submissionMessage, url, views } =
    props;

  const { state, dispatch } = useAppContext();
  const navigate = useNavigate();
  const [customDrawerOpen, setCustomDrawerOpen] = useState<boolean>(false);

  const customDrawerProps: customDialogProps = {
    open: customDrawerOpen,
    title: 'Are you sure you want to delete your page?',
    subtitle:
      'This will delete the page and its submissions and can’t be undone',
    actions: [
      {
        text: 'Delete page',
        backgroundColor: '#E32D55',
        color: '#FFFFFF',
        action: async () => {
          const response = await axiosInstance.delete(
            `${ApiRoutes.PAGES}/${id}`,
          );
          if (response.data.isSuccess) {
            dispatch({
              type: StoreActions.UPDATE_USER_LANDING_PAGES,
              payload: {
                userLandingPages: response.data.data,
              },
            });
          }
          //axios delete method
          setCustomDrawerOpen(false);
        },
      },
      {
        text: 'Keep ',
        backgroundColor: '#FFFFFF',
        color: '#000000',
        border: '1px solid #CCCCCC',
        action: () => {
          //axios delete method
          setCustomDrawerOpen(false);
        },
      },
    ],
  };

  const actions: landingPageActions[] = [
    {
      iconPath: 'assets/icons/view-submission.svg',
      action: async () => {
        await viewSubmission();
      },
      descriptions: 'View submissions',
    },
    {
      iconPath: 'assets/icons/page-action-share.svg',
      action: () => {
        shareUrl(`https://${import.meta.env.VITE_LANDING_DOMAIN}/${url}`);
      },
      descriptions: 'Share',
    },
    {
      iconPath: 'assets/icons/copy-submission.svg',
      action: () => {
        dispatch({
          type: StoreActions.UPDATE_FORM_VALUES,
          payload: {
            landingPageForm: {
              ...props,
              url: url + '-clone',
              description: description + 'clone page',
            },
          },
        });
        navigate(RouterPaths.CREATE_LANDING_PAGE);
      },
      descriptions: 'Clone',
    },
    {
      iconPath: 'assets/icons/edit.svg',
      action: () => {
        dispatch({
          type: StoreActions.UPDATE_FORM_VALUES,
          payload: {
            landingPageForm: {
              ...props,
            },
          },
        });
        navigate(RouterPaths.CREATE_LANDING_PAGE, {
          state: {
            edit: true,
          },
        });
      },
      descriptions: 'Edit',
    },
    {
      iconPath: 'assets/icons/delete.svg',
      action: () => {
        setCustomDrawerOpen(true);
      },
      descriptions: 'Delete',
      color: '#FF3A1F',
    },
  ];

  const shareUrl = async (url: string) => {
    if (navigator.share) {
      navigator
        .share({
          text: `${url}`,
        })
        .then((res) => {
          console.log({ res });
        });
    } else {
      await navigator.clipboard.writeText(`${url}`);
      alert('URL has been copied to the clipboard');
    }
  };

  const viewSubmission = async () => {
    const response = await axiosInstance.get(
      `${ApiRoutes.SUBMISSIONS}?pageId=${id}`,
    );

    if (response.data.isSuccess) {
      dispatch({
        type: StoreActions.UPDATE_PAGE_SUBMISSIONS,
        payload: {
          landingPageSubmission: response.data.data,
        },
      });
      navigate(RouterPaths.SUBMISSIONS_PAGE, {
        state: {
          edit: true,
          id,
        },
      });
      return;
    }
  };

  return (
    <Container className={style.lp_actions_container}>
      {actions.map((action, index) => (
        <Container key={index} className={style.action_row}>
          <button
            className={style.action_button}
            onClick={() => action.action()}
          >
            <Avatar
              imgProps={{
                className: style.avatar_icon,
              }}
              className={style.action_icon}
              src={action.iconPath}
            />
            <Typography className={style.action_description}>
              {action.descriptions}
            </Typography>
          </button>
        </Container>
      ))}
      <>
        <CustomDialog key={useId()} {...customDrawerProps} />
      </>
    </Container>
  );
};

export default LpActions;
