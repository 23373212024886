import './index.scss';

// import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Sentry.init({
//   dsn: import.meta.env.VITE_SENTRY_DSN,
//   environment: process.env.NODE_ENV,
//   integrations: [
//     new Sentry.BrowserTracing({
//       tracePropagationTargets: [import.meta.env.VITE_API_URL],
//     }),
//   ],
//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>
);

reportWebVitals();
