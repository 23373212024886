export enum ApiRoutes {
  LOGIN = 'login',
  PAGES = 'pages',
  REGISTER = 'register',
  GET_LANDING_PAGES = 'get-landing-pages',
  IS_REQUESTED_URL_AVAILABLE = 'url',
  CSRF = 'csrf',
  SUBMISSIONS = 'submissions',
  GOOGLE_LOGIN = 'auth/google-login',
  GOOGLE_REGISTER = 'auth/google-register',
  LOGOUT = 'auth/logout',
  LOGIN_STATUS = 'auth/login-status',
  APPLE_LOGIN = 'auth/apple-login',
}
