import { Avatar, Container, Typography } from '@mui/material';
import { sumCardProps } from '@passion/types';
import style from './Sum-card.module.scss';

export const SumCard: React.FC<sumCardProps> = (props: sumCardProps) => {
  const { desc, iconPath, sum, alt } = props;
  return (
    <Container className={style.sum_card_container}>
      <Avatar
        sx={{ height: '20px', width: '21px', borderRadius: '0%' }}
        className={style.avatar_container}
        src={iconPath}
        alt={alt}
      />
      <Typography className={style.sum}>{sum}</Typography>
      <Typography className={style.desc}>{desc}</Typography>
    </Container>
  );
};

export default SumCard;
