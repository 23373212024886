import { Button, Container, SwipeableDrawer } from '@mui/material';
import { RouterPaths, StoreActions } from '@passion/enums';
import { ApiRoutes } from '@passion/services';
import { useAppContext } from '@passion/store';
import { AppleLoginFailed, AppleLoginSuccess } from '@passion/types';
import { useGoogleLogin } from '@react-oauth/google';
import React, { useEffect, useState } from 'react';
import AppleLogin from 'react-apple-login';
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'src/utils/axios.instance';
import style from './Login.module.scss';

const LoginPage: React.FC = () => {
  const [isLoginDialog, setIsLoginDialog] = useState(false);

  const { state, dispatch } = useAppContext();

  useEffect(() => {
    setIsLoginDialog(!state.isSingUpPopUp);
  }, [state.isSingUpPopUp]);

  const navigate = useNavigate();
  /**
   * This function handles the results of an Apple login attempt and posts the data to a specified API
   * route if successful.
   * @param {AppleLoginFailed | AppleLoginSuccess} response - The parameter `response` is of type
   * `AppleLoginFailed | AppleLoginSuccess`, which means it can be either an object containing an error
   * message (`AppleLoginFailed`) or an object containing the user's login information
   * (`AppleLoginSuccess`).
   */
  const handleAppleLoginResults = async (
    response: AppleLoginFailed | AppleLoginSuccess,
  ) => {
    if ((response as AppleLoginFailed)?.error) {
      handleDialogClose();
      dispatch({
        type: StoreActions.GLOBAL_ERROR_STATE,
        payload: { globalErrorState: true },
      });
    } else {
      try {
        const data = await axiosInstance.post(
          ApiRoutes.APPLE_LOGIN,
          response as AppleLoginSuccess,
        );
        if (data.data.isSuccess) {
          if (state.isRedirectedToView) {
            dispatch({
              type: StoreActions.START_REDIRECT_PROCCESS,
              payload: { startRedirectProccess: true },
            });
          }
          dispatch({
            type: StoreActions.TOGGLE_LOGIN,
          });
          dispatch({
            type: StoreActions.UPDATE_USER,
            payload: {
              user: data.data.data,
            },
          });
          handleDialogClose();
          setIsLoginDialog(true);
          if (location.pathname === '' || location.pathname === '/') {
            navigate(RouterPaths.HOME);
          }
        }
      } catch (error) {
        handleDialogClose();
        dispatch({
          type: StoreActions.GLOBAL_ERROR_STATE,
          payload: { globalErrorState: true },
        });
      }
    }
  };

  /* `useGoogleLogin` is a custom hook provided by the `@react-oauth/google` library that allows for easy
	integration of Google OAuth2 authentication in a React application. The `googleLogin` constant is a
	function that, when called, initiates the Google login process and triggers the `onSuccess` or
	`onError` callbacks depending on the outcome of the authentication attempt. The `onSuccess` callback
	logs the token response to the console, while the `onError` callback logs the error response to the
	console. */
  const handleGoogleLogin = useGoogleLogin({
    flow: 'auth-code',
    async onSuccess(tokenResponse) {
      const response = await axiosInstance.post(
        ApiRoutes.GOOGLE_LOGIN,
        tokenResponse,
      );
      if (response.data.isSuccess) {
        if (state.isRedirectedToView) {
          dispatch({
            type: StoreActions.START_REDIRECT_PROCCESS,
            payload: { startRedirectProccess: true },
          });
        }
        dispatch({
          type: StoreActions.TOGGLE_LOGIN,
        });
        dispatch({
          type: StoreActions.UPDATE_USER,
          payload: {
            user: response.data.data,
          },
        });
        handleDialogClose();
        setIsLoginDialog(true);

        if (location.pathname === '' || location.pathname === '/') {
          navigate(RouterPaths.HOME);
        }
      }
    },
    onError(errorResponse) {
      handleDialogClose();
      dispatch({
        type: StoreActions.GLOBAL_ERROR_STATE,
        payload: { globalErrorState: true },
      });
    },
  });

  /**
   * This function handles the closing of a dialog box and toggles the state of a login popup.
   * @param {boolean} [open] - A boolean value that indicates whether the dialog should be open or
   * closed. If it is not provided, it defaults to undefined.
   * @param {any} [event] - The `event` parameter is an optional parameter of type `any` which
   * represents the event that triggered the function. It is used to check if the event type is a
   * keydown event and if the key pressed is either "Tab" or "Shift". If the event type is not a keydown
   * @returns If the `event` parameter is defined and its `type` property is `"keydown"` and its `key`
   * property is either `"Tab"` or `"Shift"`, then the function returns nothing (i.e., it exits early
   * without executing the rest of the code). Otherwise, it dispatches an action to the store to toggle
   * the login popup, with the `open` parameter as the new state
   */
  const handleDialogClose = (open?: boolean, event?: any) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    dispatch({
      type: StoreActions.TOGGLE_LOGIN_POPUP,
      payload: {
        popUpState: open,
      },
    });
  };

  const loginButtonText = isLoginDialog ? 'Continue' : 'Register';
  return (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={state.isSignUpRegisterPopUpOpen}
        onClose={($event) => handleDialogClose(false, $event)}
        onOpen={($event) => handleDialogClose(true, $event)}
        className={style.drawer}
        classes={{
          paper: style.drawer_paper,
        }}
      >
        <Container className={style.drawer_content_container}>
          <div className={style.dialog_container}>
            <h1 className={style.dialog_title}>
              {isLoginDialog ? 'Login to your account' : 'Sign up for free!'}
            </h1>
            <p className={style.dialog_subtitle}>
              {isLoginDialog
                ? 'Manage your landing pages'
                : 'Create your own landing page'}
            </p>
            <button
              onClick={() => handleGoogleLogin()}
              className={style.login_button}
            >
              <span className={`${style.login_button_icon} ${style.google}`} />
              <span className={style.login_button_text}>
                {loginButtonText} with Google
              </span>
            </button>

            <AppleLogin
              clientId={import.meta.env.VITE_APPLE_LOGIN_CLIENT_ID || ''}
              redirectURI={import.meta.env.VITE_APPLE_REDIRECT_URL}
              usePopup
              responseMode="form_post"
              scope="email name"
              callback={(appleResponse) => {
                handleAppleLoginResults(appleResponse);
                return;
              }}
              render={(props) => (
                <button
                  onClick={props.onClick}
                  className={`${style.login_button} ${style.last}`}
                >
                  <span className={style.login_button_icon} />
                  <span className={style.login_button_text}>
                    {loginButtonText} with Apple
                  </span>
                </button>
              )}
            />
            <div className={style.switch_to_register}>
              {isLoginDialog
                ? 'Don’t have an account?'
                : 'Already have an account?'}
              <Button
                className={style.register_button}
                onClick={() => setIsLoginDialog(!isLoginDialog)}
              >
                {isLoginDialog ? 'Create one for free' : 'Log in'}
              </Button>
            </div>
          </div>
        </Container>
      </SwipeableDrawer>
    </>
  );
};

export default LoginPage;
