import axios, { AxiosInstance } from 'axios';

const baseURL = import.meta.env.VITE_API_URL;

const axiosInstance: AxiosInstance = axios.create({
  baseURL, // Replace with your API base URL
  withCredentials: true,
});

export default axiosInstance;
